import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import moment from "moment";

import Layout from "../components/Layout";
import SEO from "../components/seo";

// Maybe use this https://www.npmjs.com/package/calendar-link

function KonzertTemplate({ data }) {
  let post = data.wpTermin;
  return (
    <Layout>
      <SEO
        keywords={[`Hanna Roos`, `Konzert`]}
        title={post.seo.title}
        description={post.seo.metaDesc}
      />

      <div className="container mx-auto" style={{ marginTop: 150 }}>
        <section className="mx-auto gap-6 mb-10 shadow-lg md:max-w-3xl py-8">
          <div className="text-center sm:p-6">
            <h1 className="font-header text-2xl md:text-4xl break-words">
              {post.title}
            </h1>
            <br />
            <p>{post.termine.zeitpunktBeginn}</p>
            <p dangerouslySetInnerHTML={{ __html: post.termine.ort }}></p>
            <p
              className="font-bold mt-4"
              dangerouslySetInnerHTML={{ __html: post.termine.weitereInfo }}
            ></p>
            <br /> <br />
          </div>
          <div
            className="prose mx-auto px-2"
            dangerouslySetInnerHTML={{ __html: post.content }}
          ></div>
        </section>
      </div>
    </Layout>
  );
}

KonzertTemplate.propTypes = {
  data: PropTypes.any,
};

export const query = graphql`
  query ($uri: String!) {
    wpTermin(uri: { eq: $uri }) {
      title
      id
      uri
      content
      termine {
        ort
        weitereInfo
        zeitpunktBeginn
        zeitpunktEnde
      }
      seo {
        title
        metaDesc
        metaKeywords
      }
    }
  }
`;

export default KonzertTemplate;
